import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label } from 'reactstrap';
import { parseNumber, formatNumber, getCountryCallingCode } from 'libphonenumber-js';
import { isValidNumber, getNumberType } from 'libphonenumber-js/custom';
import metadata from 'libphonenumber-js/metadata.full.json';
import { convertStringToBoolean } from "../../../../utils/CommonUtils";
import i18n from '../../../../i18next';
import MaterialIcon from 'material-icons-react';
import { ARABIC, FRONT_END_URL } from '../../../../utils/Constants';
import phoneIconSrc from '../../../../images/icons/icon-phone-grey.png';
import TickSVG from '../../common/svgComponents/TickSVG';

class SocialUserCredentialsCommon extends Component {

    constructor(props) {
        super(props);

        this.state = {
            socialProvider: '',
            userData: null,
            newsletter: false,
            stateAlreadyCopiedFromProps: false,
            countryCallingCode: null,
            phone: null,
            isPhoneTyped: false,
            countryCodeCoppiedToPhoneInput: false,
            showAppleUserNameInput: false,
            appleUserName: '',
            isSpecialSymbols: false      
        };

        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        let countryCallingCode = this.props.brand.countryCode;
        
        if (countryCallingCode) {
            countryCallingCode = getCountryCallingCode(this.props.brand.countryCode);

            this.setState({
                countryCallingCode: countryCallingCode
            });
        }

        if (this.props.socialProvider) {
            if (this.props.socialProvider === 'google' && this.props.googleUserData) {
                this.setState({
                    socialProvider: 'google',
                    userData: this.props.googleUserData
                });
            } else if (this.props.socialProvider === 'facebook' && this.props.facebookUserData) {
                this.setState({
                    socialProvider: 'facebook',
                    userData: this.props.facebookUserData
                });
            } else if (this.props.socialProvider === 'apple' && this.props.appleUserData) {
                this.setState({
                    socialProvider: 'apple',
                    userData: this.props.appleUserData,
                    showAppleUserNameInput: this.props.isAppleUserNameEmpty
                });
            }
        }
    }

    registerCustomer(state) {
        let isPWA = false;
        if (this.props.isPWA) {
          isPWA = true;
        }
        
       this.props.registerCustomer(state, this.props.customer, isPWA, this.props.brand );
    }

    openPrivacyPolicyPage() {
        let termsAndConditionsPage = window.open('https://menuu.com/privacy-policy', '_blank');
        termsAndConditionsPage.focus();
    }

    changeState({ target }) {
        let targetValue = target.value;
        if (target.name == 'newsletter') {
            targetValue = convertStringToBoolean(target.value);

            this.setState({
                [target.name]: !targetValue
            });

        } else if (target.name === 'appleUserName' && target.value !== null && target.value !== "" && target.value !== undefined) {
            let regex = /[!a-zA-ZÀ-úА-яЁё\u0620-\u064Aa0-9_.-\s]+/g;
    
            let nameValue = target.value;
            let isNameInValidFormat = nameValue.match(regex);
            let isSpecialSymbols = nameValue.split(regex).join("");
            const verifiedNameInput = isNameInValidFormat && isNameInValidFormat !== null ? isNameInValidFormat.join("") : '';
            
            if(isSpecialSymbols) {
                this.setState({
                    appleUserName: verifiedNameInput.trimStart(),
                    isSpecialSymbols: true,
                })
            } else {
                this.setState({
                    appleUserName: verifiedNameInput.trimStart(),
                    isSpecialSymbols: false
                });
            }
        } else {
            this.setState({
                [target.name]: target.value
            },
                () => {

                    let phoneVariable = !this.state.phone && this.state.phone !== 0 ? '' : this.state.phone; // Needed because parseNumber demands a string
                    let phoneNumber = null;
                    let phoneNumbers = [parseNumber(`+${this.state.countryCallingCode}${phoneVariable}`), parseNumber(`${phoneVariable}`), parseNumber(`+${phoneVariable}`), parseNumber(`${phoneVariable}`, this.props.brand.countryCode)];

                    for (let i = 0; i < phoneNumbers.length; i++) {
                        let num = phoneNumbers[i];

                        if (Object.keys(num).length !== 0) {
                            const formatNumberToInternational = formatNumber(num, 'International');
                            const validatedNumber = isValidNumber(formatNumberToInternational, metadata);
                            const isMobileNumber = getNumberType(formatNumberToInternational, metadata);
                            if (validatedNumber && isMobileNumber.includes("MOBILE")) {
                                phoneNumber = num;
                                break;
                            }
                        }
                    }

                    if (!phoneNumber) { // Checks if phoneNumber is too long and exits the function
                        this.setState({
                            submitBtnDisabled: true,
                            isPhoneTyped: false
                        });
                        return;
                    } else {
                        this.setState({
                            submitBtnDisabled: false,
                            isPhoneTyped: true
                        })
                    }
                });
        }
        
        if (this.props.customer.phoneNumberErrMsg) {
            this.props.resetErrMsg()
        }
    }

    renderContent() {
        const { subHeading, inputGroup, inputGroupTickbox, inputGroupText, input, label, marginAuto, privacyPolicy, phoneIconStyle, phoneIconStyleRTL, checkIconStyle, checkIconStyleRTL, termsAndConditions, subMsg } = this.props.styles;
        const { primaryColor, textDecoration, primaryFontColor, menuItemsBackground, fontFamilyType, fontBold, fontItalic, uppercase, type, secondaryColor, inputsFontColor, buttonOutlineColor } = this.props.customerThemes.selectedTheme;
        const { isPWA } = this.props;

        const isArabic = this.props.language === ARABIC;
        let isHidden = (this.state.isPhoneTyped) ? false : true;

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const titleStyle = {
            margin: isPWA ? '20px auto 5px' : 'auto',
            textAlign: 'center',
            fontSize: 22,
            color: primaryFontColor,
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        const countryCallingCodeStyle = {
            width: 55, 
            maxWidth: 55, 
            borderRadius: 0, 
            borderRightWidth: 0, 
            textAlign: 'start', 
            paddingLeft: 16, 
            color: inputsFontColor 
        }
        const countryCallingCodeStyleRTL = {
            width: 55, 
            maxWidth: 55, 
            borderRadius: 0, 
            borderLeftWidth: 0, 
            textAlign: 'start', 
            paddingRight: 16, 
            color: inputsFontColor 
        }

        return (
            <div className='modal-body-custom'>
                <Col sm={12} className='modal-custom-body-title' style={{ flexDirection: 'column', height: 'auto', maxHeight: 'unset' }}>
                    <p style={{...subHeading, paddingBottom: '0px'}}>{i18n.t('screens:loginTypesScreen.almostDone')}</p>
                    <p style={subHeading}>{i18n.t('screens:loginTypesScreen.verifyPin')}</p>
                </Col>
                <Col xs={12} md={10} style={{ margin: '10px auto 0', flexDirection: 'column', justifyContent: 'center', flex: 1 }} className='modal-custom-body-inner-content'>
                    <Col xs={12} style={marginAuto}>
                        <div style={{ marginBottom: '10px'}}>
                          <InputGroup style={{ ...inputGroup, ...{ marginBottom: 10 } }}>
                              { this.state.showAppleUserNameInput &&
                                  <Input
                                      id="appleUserName" type="text"
                                      name="appleUserName"
                                      placeholder='Apple User Name'
                                      onFocus={(e) => e.target.placeholder = ''}
                                      onBlur={(e) => e.target.placeholder = 'Your Apple User Name'}
                                      value={this.state.appleUserName}
                                      onChange={this.changeState}
                                      style={Object.assign({}, input, { borderColor: buttonOutlineColor })}
                                      className='addressInputs'
                                  />
                                }
                            </InputGroup>
                                {
                                    this.state.isSpecialSymbols ? 
                                        <div style={{width: '100%', color: 'red', textAlign: 'left', marginBottom: '10px'}}>{i18n.t('common:alerts.allowedCharacters')}</div> : null
                                }
                            <InputGroup style={{ ...inputGroup, ...{ marginBottom: 3 } }}>
                                <div style={{ position: 'absolute', zIndex: 4, padding: '13px 8px', pointerEvents: 'none', color: inputsFontColor }}>+</div>

                                <Input
                                    id='countryCallingCode'
                                    name='countryCallingCode' type='text' autoComplete='none'
                                    autoFocus
                                    onFocus={e => e.currentTarget.select()}
                                    // style={ isArabic ? { ...input, ...countryCallingCodeStyleRTL} : { ...input, ...countryCallingCodeStyle}}
                                    style={Object.assign({}, { borderColor: buttonOutlineColor }, isArabic ? { ...input, ...countryCallingCodeStyleRTL} : { ...input, ...countryCallingCodeStyle})}
                                    // onChange={(e) => this.setState({ ...this.state, countryCallingCode: e.target.value })}
                                    onChange={this.changeState}
                                    value={`${this.state.countryCallingCode}`} />
                                    
                                <img src={phoneIconSrc} width="30" height="auto" style={isArabic ? phoneIconStyleRTL : phoneIconStyle} alt='' />
                                <Input
                                    id="phone" type="text"
                                    name="phone"
                                    placeholder='123456789'
                                    onFocus={(e) => e.target.placeholder = ''}
                                    onBlur={(e) => e.target.placeholder = '123456789'}
                                    value={this.state.phone}
                                    onChange={this.changeState}
                                    style={Object.assign({}, input, { borderColor: buttonOutlineColor })}
                                    className='addressInputs'
                                />
                                <InputGroupAddon addonType="append">
                                    <TickSVG
                                        width={30} height={30}
                                        fill={type === 'DARK' ? secondaryColor : primaryColor}
                                        style={isArabic ? checkIconStyleRTL : checkIconStyle} hidden={isHidden} />
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                        <div hidden={!this.props.customer.phoneNumberErrMsg} style={{ color: 'red', textAlign: 'left', padding: '0 9px' }}>
                            {this.props.customer.phoneNumberErrMsg}
                        </div>
                        <InputGroup style={inputGroupTickbox}>
                            <InputGroupAddon id="userCredentialsPrepender" addonType="prepend" style={{backgroundColor: menuItemsBackground, color: primaryFontColor}}>
                                <InputGroupText style={{...inputGroupText, ...{backgroundColor: menuItemsBackground}}}>
                                    <Input addon type="checkbox" name="newsletter" value={this.state.newsletter} onChange={this.changeState} />
                                </InputGroupText>
                                <Label style={label} for="newsletter">{i18n.t('screens:userCredentialsScreen.newsletterMsg')} <span onClick={() => this.openPrivacyPolicyPage()} style={Object.assign({}, privacyPolicy, {color: primaryColor, textDecoration: textDecoration})}>{i18n.t('screens:userCredentialsScreen.privacyPolicy')}</span>
                                </Label>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Col>
                {this.renderModalFooter()}
            </div>
        );
    }

    renderModalFooter() {
        const { submitBtn, submitBtnDisabled, modalCustomSubmitBody } = this.props.styles;
        const { primaryColor, inactiveColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        let regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;

        let isDisabled = true;
        if (this.state.showAppleUserNameInput) {
            if (this.state.appleUserName && this.state.isPhoneTyped) { 
                isDisabled = false;
            }
        } else if (this.state.isPhoneTyped) {
            isDisabled = false;        	
        }

        let selectSubtmitButtonStyle, submitBtnClassName, activeInactiveBtn, submitBtnText;

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const submitBtnFontStyle = {
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }
        submitBtnText = i18n.t('screens:loginTypesScreen.sendCode');

        if (!isDisabled) {
            selectSubtmitButtonStyle = submitBtn;
            submitBtnClassName = "submitNumber";
            activeInactiveBtn = { backgroundColor: primaryColor, color: buttonsFont };
        } else {
            selectSubtmitButtonStyle = submitBtnDisabled;
            submitBtnClassName = "";
            activeInactiveBtn = { backgroundColor: inactiveColor, color: buttonsFont };
        }

        return (
            <Row className='' style={modalCustomSubmitBody}>
                <Col xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col xs={12} sm={8} className="submitNumberBtn">
                        <Button disabled={isDisabled} className={submitBtnClassName} style={Object.assign({}, selectSubtmitButtonStyle, activeInactiveBtn, submitBtnFontStyle, { padding: 0, alignItems: 'center', textTransform: 'normal' })} onClick={() => this.registerCustomer(this.state)}>
                            {submitBtnText}
                        </Button>
                    </Col>
                </Col>
            </Row>
        );
    }
    
    render() {
        return (
            <React.Fragment>
                {this.renderContent()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        googleUserData: state.customer.googleUserData,
        facebookUserData: state.customer.facebookUserData,
        appleUserData: state.customer.appleUserData
    };
};

export default connect(mapStateToProps, {})(SocialUserCredentialsCommon);
