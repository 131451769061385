import axios from '../../utils/AxiosUtils';
import { IN_STORE_ID, GENERIC_API_KEY } from '../../utils/Constants';
import {
    CLEAR_STATE,
    CLOSE_START_ORDER_SCREEN,
    LOAD_CUSTOMER_FROM_LOCAL_STORAGE,
    LOGIN_SUCCESS, OPEN_START_ORDER_SCREEN,
    SAVE_CUSTOMER_ID,
    SAVE_CUSTOMER_PHONE,
    SHOW_ERROR_DIALOG,
    SHOW_SUCCESS_ALERT_MESSAGE,
    GET_CUSTOMER_CAR,
    SAVE_CC_PREFIX,
    SHOW_ERROR_MSG,
    RESET_PHONE_NUMBER_ERR_MSG,
    TOGGLE_SHOPPING_CART_MODAL,
    TOGGLE_PROMOTIONS_MODAL,
    IS_LOGIN_CONFIRMATION,
    IS_FORGOTTEN_EMAIL_CONFIRMATION,
    REGISTER_SUCCESS,
    RESEND_SUCCESS_MESSAGE,
    SAVE_CUSTOMER_EMAIL,
    RESET_LOGIN_CONFIRMATION,
    RESET_FORGOTTEN_EMAIL_CONFIRMATION,
    GET_CAPTCHA_SUCCESS,
    RESET_CUSTOMER_SAVED_ADDRESSES_SUCCESS,
    LOADING_NO_BACKGROUND,
    SAVE_GOOGLE_USER_DATA,
    SAVE_FACEBOOK_USER_DATA,
    SAVE_APPLE_USER_DATA,
    NEW_SOCIAL_LOGIN
} from '../types';
import { parentLocalStorage } from '../../utils/CrossOriginUtils';
import { push, goBack } from "connected-react-router";
import { doLoadCustomerSavedDeliveryAddresses, doLoadCustomerSavedRestaurants } from "../order/start/RestaurantsActions";
import { clearStoreLocalStorageState } from '../StoreLocalStorageActions';
import { getCountryCallingCode, parseNumber, formatNumber } from 'libphonenumber-js';
import { decryptData, hashKey } from '../../utils/CommonUtils';

export const submitLogin = (phoneNumber, isOrderDone, isPWA, countryCodePrefix, email, userData, isCheckoutLogin) => {

    return (dispatch, getState) => {

        let phone = phoneNumber;

        if (phoneNumber && phoneNumber.includes("+")) {
            phone = phoneNumber.replace(phoneNumber[0], '%2B');
        }

        let countryCode = '';
        // if (phoneNumber && phoneNumber[0].includes("0")) {
        countryCode = getState().brand.countryCode;
        // }

        const brandId = getState().brand.id;

        let decrData = null;                
        let socialProviderName = '';
        let url = '';

        if (userData) {
            const year = new Date().getFullYear();
            const key = hashKey(`${GENERIC_API_KEY}${brandId}${year}`);
 
            decrData = decryptData(userData, key);
        }
        
        if (decrData && decrData.socialProvider) {
            socialProviderName = decrData.socialProvider;
            url = `/customer/login?brandId=${brandId}&email=${decrData.email}&socialProvider=${socialProviderName}&accountId=${decrData.accountId}&jwtToken=${decrData.jwtToken}`;
        } else {
            socialProviderName = 'menuu';
            url = `/customer/login?phone=${phone}&countryCode=${countryCode}&brandId=${brandId}&countryCodePrefix=%2B${countryCodePrefix}&email=${email}&socialProvider=${socialProviderName}`;
        }

        axios(getState).get(url)
            .then((response) => {
                if (socialProviderName === 'menuu') {
                    return submitLoginSuccess(dispatch, response, phone, isOrderDone, isPWA, brandId, countryCodePrefix, email);           	
                } else {
                    return socialLoginSuccess(dispatch, response, getState, isPWA, brandId, decrData.email, socialProviderName);
                }
             })
            .catch((error) => {
                console.log(error.response);
                dispatch({ type: SHOW_ERROR_MSG, payload: error.response.data.userMessage })
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });

                // Social Login Failed - user does not exist in database and will be redirected to register
                if (error.response && error.response.data.success === false && !error.response.data.data && socialProviderName !== 'menuu') {
                  const newSocialLoginInfo = {
                      providerName: socialProviderName,
                      isCheckoutLogin: isCheckoutLogin
                  }
                  dispatch({ type: NEW_SOCIAL_LOGIN, payload: newSocialLoginInfo});
                }
            });
    };
};

const submitLoginSuccess = (dispatch, response, phone, isOrderDone, isPWA, brandId, countryCodePrefix, email) => {

    dispatch({ type: IS_LOGIN_CONFIRMATION, payload: true });  
    dispatch({ type: SAVE_CUSTOMER_ID, payload: response.data.data[0].id });
    dispatch({ type: SAVE_CUSTOMER_PHONE, payload: phone });
    dispatch({ type: SAVE_CC_PREFIX, payload: countryCodePrefix });
    dispatch({ type: SAVE_CUSTOMER_EMAIL, payload: email })

    const isNewCustomer = (response.data.data[0].id == -1) ? true : false;

    parentLocalStorage('set', 'isNewCustomer', isNewCustomer);

    if (!isPWA) {
        if (isOrderDone) {
            dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/menu-screen/sms-confirmation` });
        } else {
            dispatch(push(`/start-order/sms-confirmation`));
        }
    } else {
        dispatch(push(`/brand/${brandId}/profile/login/sms-confirmation`));
    }
};

const socialLoginSuccess = (dispatch, response, getState, isPWA, brandId, email, socialProviderName) => {

    let phone = null;
    let countryCodePrefix = null;

    if (response.data) {
        if (response.data.data[0].formattedPhone) {
            const phoneNumber = response.data.data[0].formattedPhone;
            const parsedNumber = parseNumber(phoneNumber);
            phone = formatNumber(parsedNumber, 'International').replace(/\s+/g, '');
            countryCodePrefix = getCountryCallingCode(parsedNumber.country);
        }
    }

    dispatch({ type: IS_LOGIN_CONFIRMATION, payload: true });  
    dispatch({ type: SAVE_CUSTOMER_ID, payload: response.data.data[0].id });
    dispatch({ type: SAVE_CUSTOMER_PHONE, payload: phone });
    dispatch({ type: SAVE_CC_PREFIX, payload: countryCodePrefix });
    dispatch({ type: SAVE_CUSTOMER_EMAIL, payload: email });
    dispatch({ type: LOADING_NO_BACKGROUND, payload: false });

    verifyCustomerPhoneSuccess(dispatch, response, getState, isPWA, brandId, socialProviderName);    
}

export const saveGoogleUserData = (googleUserData) => {
    return (dispatch) => {

        dispatch({ type: SAVE_GOOGLE_USER_DATA, payload: googleUserData });
    };
};

export const saveFacebookUserData = (facebookUserData) => {
    return (dispatch) => {

        dispatch({ type: SAVE_FACEBOOK_USER_DATA, payload: facebookUserData });
    };
};

export const saveAppleUserData = (appleUserData) => {
    return (dispatch) => {

        dispatch({ type: SAVE_APPLE_USER_DATA, payload: appleUserData });
    };
};

export const newSocialLogin = (newSocialLoginInfo) => {
    return (dispatch) => {

        dispatch({ type: NEW_SOCIAL_LOGIN, payload: newSocialLoginInfo });
    };
};

export const verifyCustomerPhone = (phoneNumber, code, countryCode, isPWA, email) => {

    return (dispatch, getState) => {

        let phone = phoneNumber;

        if (phoneNumber && phoneNumber.includes("+")) {
            phone = phoneNumber.replace(phoneNumber[0], '%2B');
        }

        if (phoneNumber && phoneNumber[0].includes("0") && countryCode == undefined || countryCode == null) {
            countryCode = getState().brand.countryCode;
        }

        const brandId = getState().brand.id;
        const countryCodePrefix = getState().customer.countryCodePrefix;
        const url = `/customer/verify?phone=${phone}&pinCode=${code}&countryCode=${countryCode}&countryCodePrefix=%2B${countryCodePrefix}&email=${email}`;

        axios(getState).get(url)
            .then(response => verifyCustomerPhoneSuccess(dispatch, response, getState, isPWA, brandId))
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_MSG, payload: error.response.data.userMessage })
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    };

};

const verifyCustomerPhoneSuccess = (dispatch, response, getState, isPWA, brandId, socialProviderName) => {
    parentLocalStorage('get', 'token')
        .then((tokenData) => {
            dispatch({ type: LOGIN_SUCCESS, payload: response.data });
            const { selectedOrderType } = getState().orderTypes;
            const customer = response.data.data[0];
            const token = response.data.token;
            const tokenFromStorage = tokenData;
            const isShoppingCartEmpty = (getState().shoppingCart.orderProducts.length > 0) ? false : true;
            const isMobileView = window.innerWidth <= 865;
            const { promotionPartner } = getState().promotionPartners;
            const isPromotionProviders = promotionPartner ? promotionPartner.id !== 1 ? true : false : false;
            saveCustomerToLocalStorage(customer, token);

            if (!isPWA) {
                if ((response.data.token !== undefined && response.data.token !== null) || (tokenFromStorage !== undefined && tokenFromStorage !== null)) {
                    if (isShoppingCartEmpty && getState().startOrder.currentPagePath !== '/menu-screen/sms-confirmation') {
                        if (getState().startOrder.currentPagePath === '/menu-screen' && socialProviderName && socialProviderName !== 'menuu') {
                            dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
                        } else {
                            doLoadCustomerSavedDeliveryAddresses(dispatch, getState);
                            doLoadCustomerSavedRestaurants(dispatch, getState);
                            dispatch(push(`/start-order/order-type`));
                        }

                    } else if (isShoppingCartEmpty && getState().startOrder.currentPagePath === '/menu-screen/sms-confirmation' && selectedOrderType.id !== IN_STORE_ID) {
                        // doLoadCustomerSavedDeliveryAddresses(dispatch, getState);
                        // doLoadCustomerSavedRestaurants(dispatch, getState);
                        dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
                    } else if (isShoppingCartEmpty && getState().startOrder.currentPagePath === '/menu-screen/sms-confirmation' && selectedOrderType.id === IN_STORE_ID) {
                        dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
                    } else if (!isShoppingCartEmpty && getState().storeLocalStorage.signInPressed) {
                        dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: '/menu-screen' });
                    } else {
                        if (isMobileView) {
                            dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
                            dispatch({ type: TOGGLE_SHOPPING_CART_MODAL });
                        } else {
                            if (isPromotionProviders) {
                                dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
                                dispatch({ type: TOGGLE_PROMOTIONS_MODAL });
                            } else {
                                dispatch(push(`/checkout`));
                                dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/checkout` });
                            }
                        }
                    }
                }
            } else {
                if ((response.data.token !== undefined && response.data.token !== null) || (tokenFromStorage !== undefined && tokenFromStorage !== null)) {
                    if (isShoppingCartEmpty && getState().startOrder.currentPagePath !== `/brand/${brandId}/profile/login/sms-confirmation`) {
                        doLoadCustomerSavedDeliveryAddresses(dispatch, getState);
                        doLoadCustomerSavedRestaurants(dispatch, getState);

                        let temporaryPath = getState().temporaryOrderTime.currentPagePath;
                        dispatch(push(temporaryPath));

                    } else if (isShoppingCartEmpty && getState().startOrder.currentPagePath === `/brand/${brandId}/profile/login/sms-confirmation`) {
                        doLoadCustomerSavedDeliveryAddresses(dispatch, getState);
                        doLoadCustomerSavedRestaurants(dispatch, getState);
                        dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/brand/${brandId}/menu-screen/order/menu` });
                    } else {
                        if (isPromotionProviders) {
                            dispatch(push(`/brand/${brandId}/order/promotions`));
                            dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/brand/${brandId}/order/promotions` });
                        } else {
                            dispatch(push(`/brand/${brandId}/order/checkout`));
                            dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/brand/${brandId}/order/checkout` });
                        }
                    }
                }
            }
        })
};

export const registerCustomer = (state, customer, isPWA, brand, callback) => {
    return (dispatch, getState) => {
        
        parentLocalStorage('get', 'customerId')
            .then((customerIdData) => {
                let countryCode = '';
                let registerCustomerRequest = {};
               
                countryCode = getState().brand.countryCode;
                
                if (state.socialProvider && state.userData) {
                    const year = new Date().getFullYear();
                    const key = hashKey(`${GENERIC_API_KEY}${brand.id}${year}`);
                    const decrData = decryptData(state.userData, key);

                    const socialUserName = decrData.name ? decrData.name : '';
                    const appleUserName = (state.socialProvider === 'apple' && state.appleUserName) ? state.appleUserName : '';
                    const validUserName = socialUserName ? socialUserName : appleUserName;
                    const socialUserEmail = decrData.email ? decrData.email : '';
                    const socialUserAccountId = decrData.accountId ? decrData.accountId : '';
                    const socialUserJwtToken = decrData.jwtToken ? decrData.jwtToken : '';
                    
                    registerCustomerRequest = {
                        phone: state.phone ? state.phone : '',
                        countryCode: countryCode,
                        countryCodePrefix: `+${state.countryCallingCode}`,
                        name: validUserName,
                        email: socialUserEmail,
                        accountId: socialUserAccountId,
                        jwtToken: socialUserJwtToken,
                        brandId: brand.id,
                        socialProvider: state.socialProvider
                    }
                } else {
                    const answerId = getState().customer.captcha.id;
                    registerCustomerRequest = {
                        phone: state.phone ? state.phone : '',
                        countryCode: countryCode,
                        countryCodePrefix: `+${state.countryCallingCode}`,
                        name: state.customerName,
                        email: state.customerEmail,
                        brandId: brand.id,
                        answerId: answerId,
                        answer: state.captchaCode,
                        socialProvider: 'menuu'
                    }
                }

                const marketingPreferences = state.newsletter;

                const customerId = customerIdData;

                const brandId = getState().brand.id;

                const url = `/customer/register`;

                axios(getState).post(url, registerCustomerRequest)
                    .then(response => registerCustomerSuccess(dispatch, getState, response, marketingPreferences, isPWA, brandId, state, callback))
                    .catch(error => {
                    (error.response.data.userMessage == 'Customer not found!') ? registerCustomerSuccess(dispatch, getState) : 
                    console.log(error.response)
                    dispatch({ type: SHOW_ERROR_MSG, payload: error.response.data.userMessage })
                    dispatch({ type: SHOW_ERROR_DIALOG, payload: true })});
                    if(callback) {
                        callback()
                    }
            });
    };
};

const registerCustomerSuccess = (dispatch, getState, response, marketingPreferences, isPWA, brandId, state, callback) => {

    if (response !== undefined && response !== null) {
        // dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        dispatch({ type: REGISTER_SUCCESS, payload: state })
        dispatch( { type: SAVE_CUSTOMER_ID, payload: response.data.data[0].customerId })

        const customer = response.data.data[0];
        const token = response.data.token;
        saveCustomerToLocalStorage(customer, token);

        // Call marketing-preferences only if chosen
        if (marketingPreferences) {
            doCreateOrUpdateMarketingPreferences(marketingPreferences, token, getState, dispatch);
        }

        if(callback) {
            callback()
        }
    }

    const isShoppingCartEmpty = (getState().shoppingCart.orderProducts.length > 0) ? false : true;
    const { promotionPartner } = getState().promotionPartners;
    const isPromotionProviders = promotionPartner ? promotionPartner.id !== 1 ? true : false : false;
    const isMobileView = window.innerWidth <= 865;

    if (!isPWA) {
        const getCurrPagePath = getState().startOrder.currentPagePath;
        if (getCurrPagePath !== '/menu-screen/user-credentials' && getCurrPagePath !== '/menu-screen/social-credentials') {
            dispatch(push(`/start-order/sms-confirmation`));
        } else {
            dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/menu-screen/sms-confirmation` });
        }
    } else {
        dispatch(push(`/brand/${brandId}/profile/login/sms-confirmation`));
    }

    // if (!isPWA) {
    //     if (isShoppingCartEmpty && getState().startOrder.currentPagePath !== '/menu-screen/user-credentials') {
    //         dispatch(push(`/start-order/sms-confirmation`));
    //     } else if (isShoppingCartEmpty && getState().startOrder.currentPagePath === '/menu-screen/user-credentials') {
    //         // dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
    //         dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/menu-screen/sms-confirmation` });
    //     } else {
    //         if (isPromotionProviders) {
    //             if (isMobileView) {
    //                 dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
    //                 dispatch({ type: TOGGLE_SHOPPING_CART_MODAL });
    //             } else {
    //                 dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/menu-screen` });
    //                 dispatch({ type: TOGGLE_PROMOTIONS_MODAL });
    //             }
    //         } else {
    //             dispatch(push(`/checkout`));
    //             dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/checkout` });
    //         }
    //     }
    // } else {
    //     if (!isShoppingCartEmpty && isPWA) {
    //         dispatch(push(`/brand/${brandId}/order/cart`));
    //         dispatch({ type: CLOSE_START_ORDER_SCREEN, payload: `/brand/${brandId}/order/cart` });
    //     } else {
    //         let temporaryPath = getState().temporaryOrderTime.currentPagePath;
    //         dispatch(push(temporaryPath));
    //     }
    // }

    dispatch({ type: SAVE_GOOGLE_USER_DATA, payload: null });
    dispatch({ type: SAVE_FACEBOOK_USER_DATA, payload: null });
    dispatch({ type: SAVE_APPLE_USER_DATA, payload: null });
};

const doCreateOrUpdateMarketingPreferences = (marketingPreference, token, getState) => {

    parentLocalStorage('get', ['customerId', 'token'])
        .then(localstorageData => {
            let customerId, data;
            customerId = localstorageData[0];

            data = {
                restaurantChainId: getState().storeLocalStorage.brandId,
                allowReceivingMarketingMaterials: marketingPreference
            }

            const url = `/customer/${customerId}/marketing-preferences`;
            // const header = { headers: { 'Authorization': `TOKEN${localstorageData[1]}` } };

            axios(getState).put(url, data)//, header
                .catch(error => console.log(error));
        });
};

export const saveCustomerToLocalStorage = (customer, token) => {

    if (token !== undefined && token !== null && token !== '') {
        parentLocalStorage('set', 'token', token);
    }

    const customerAddress = JSON.stringify(customer.addresses);


    parentLocalStorage('set', 'customerId', customer.id);
    parentLocalStorage('set', 'name', customer.name);
    parentLocalStorage('set', 'email', customer.email);
    parentLocalStorage('set', 'phone', customer.phone);
    parentLocalStorage('set', 'addresses', customerAddress);
    parentLocalStorage('set', 'countryCode', customer.countryCode);
    parentLocalStorage('set', 'autoApplyLoyalty', customer.autoApplyLoyalty);
};

export const loadCustomerFromLocalStorage = () => {
    return (dispatch) => {

        const localStorageRequest = ['addresses', 'customerId', 'email', 'token', 'name', 'phone', 'countryCode', 'autoApplyLoyalty']
        parentLocalStorage('get', localStorageRequest)
            .then(localstorageData => {

                const addresses = localstorageData[0];

                const customerAddresses = (addresses == 'undefined' || addresses == undefined || addresses == null) ? '' : JSON.parse(addresses);

                const customer = {
                    id: localstorageData[1],
                    email: localstorageData[2],
                    token: localstorageData[3],
                    name: localstorageData[4],
                    phone: localstorageData[5],
                    addresses: customerAddresses,
                    countryCode: localstorageData[6],
                    autoApplyLoyalty: localstorageData[7]
                };

                dispatch({ type: LOAD_CUSTOMER_FROM_LOCAL_STORAGE, payload: customer });
            });
    };
};

export const logoutCustomer = (isPWA, queryParams) => {
    return (dispatch, getState) => {

        parentLocalStorage('clear');
        dispatch(clearStoreLocalStorageState());
        // dispatch(changeCodeManuallySet(null));
        // dispatch(changeSignInPressed(null));
        const pwaAppRunning = getState().pwaAppRunning;

        dispatch({ type: CLEAR_STATE, fromPwa: pwaAppRunning });

        if (!isPWA && !queryParams) {
            dispatch(push('/start-order/order-type'));
        } else if (!isPWA && queryParams) {
            dispatch(push(`${queryParams}#/dine-pay`));
            dispatch(goBack()); //TODO find out why this works and not only one push
        } else {
            const brandId = window.location.href.split("/")[5];

            dispatch({ type: RESET_CUSTOMER_SAVED_ADDRESSES_SUCCESS });
            if(window.location.href.includes('/main-screen')) {
                // Need to reload the main screen for the Language Switcher
                window.location.reload();
            }
            dispatch(push(`/brand/${brandId}/start-order/main-screen`));
        }
    };
};

export const doClearState = (dispatch, dispatchSuccessMessage, isDeactivatedUser) => {
    parentLocalStorage('get', 'menuuReduxStore')
        .then(localstorageData => {
            const menuuReduxStore = JSON.parse(localstorageData);
            const brandId = menuuReduxStore.brand.id;
            const isPwa = menuuReduxStore.pwaAppRunning;
            if (isPwa) {
                dispatch(push(`/brand/${brandId}/start-order/main-screen`));
            } else {
                dispatch(push('/start-order/order-type'));
            }

            if (dispatchSuccessMessage) {
                dispatch({ type: SHOW_SUCCESS_ALERT_MESSAGE, payload: true });
            }

            dispatch({ type: CLEAR_STATE })
            parentLocalStorage('remove', 'menuuReduxStore');
            parentLocalStorage('remove', 'addresses');
            // parentLocalStorage('remove', 'brandId');
            parentLocalStorage('remove', 'countryCode');

            if (isDeactivatedUser) {
                parentLocalStorage('remove', 'customerId');
                parentLocalStorage('remove', 'name');
                parentLocalStorage('remove', 'email');
                parentLocalStorage('remove', 'phone');
                parentLocalStorage('remove', 'token');
                parentLocalStorage('remove', 'isNewCustomer');
            }
        })
}

export const getCustomerCar = (customerId) => {
    return (dispatch, getState) => {

        const url = `/customer/${customerId}/restaurants/curbside`;

        axios(getState).get(url)
            .then(response => {
                if (response.status !== 204) {
                    dispatch({ type: GET_CUSTOMER_CAR, payload: response.data })
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const resetPhoneNumberErrMsg = () => {
    return (dispatch) => {

        dispatch({ type: RESET_PHONE_NUMBER_ERR_MSG, payload: null });
    };
};

export const forgottenEmail = (phoneNumber, isOrderDone, isPWA, brandId, countryCodePrefix, captchaValue, callback) => {
    return (dispatch, getState) => {

        let phone = phoneNumber;

        if (phoneNumber && phoneNumber.includes("+")) {
            phone = phoneNumber.replace(phoneNumber[0], '%2B');
        } else if (!phone) {
            phone = ''
        }

        let countryCode = '';
        countryCode = getState().brand.countryCode;
        const answerId = getState().customer.captcha.id;

        const url = `customer/change-email-request?phone=${phone}&countryCode=${countryCode}&brandId=${brandId}&countryCodePrefix=%2B${countryCodePrefix}&token=${captchaValue}&answerId=${answerId}&answer=${captchaValue}`;

        axios(getState).put(url)
            .then(response => forgottenEmailSuccess(dispatch, response, phone, isOrderDone, isPWA, brandId, countryCodePrefix, callback) )
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_MSG, payload: error.response.data.userMessage })
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
                if(callback) {
                    callback()
                }
            });
    };
};

const forgottenEmailSuccess = (dispatch, response, phone, isOrderDone, isPWA, brandId, countryCodePrefix, callback) => {
    
    dispatch({ type: IS_FORGOTTEN_EMAIL_CONFIRMATION, payload: true });
    dispatch({ type: SAVE_CUSTOMER_ID, payload: response.data.data[0].customerId });
    dispatch({ type: SAVE_CUSTOMER_PHONE, payload: phone });
    dispatch({ type: SAVE_CC_PREFIX, payload: countryCodePrefix });


    if (!isPWA) {
        if (isOrderDone) {
            dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/menu-screen/sms-confirmation` });
        } else {
            dispatch(push(`/start-order/sms-confirmation`));
        }
    } else {
        dispatch(push(`/brand/${brandId}/profile/login/sms-confirmation`));
    }

    if(callback) {
        callback()
    }
}   

export const reSendPinCode = (customerId, email, brandId, answer, callback) => {
    return (dispatch, getState) => {

        const answerId = getState().customer.captcha.id;

        const url = `customer/${customerId}/resend-code?email=${email}&brandId=${brandId}&answerId=${answerId}&answer=${answer}`;

        axios(getState).put(url)
            .then(response => reSendPinCodeSuccess(dispatch, response, callback))
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_MSG, payload: error.response.data.userMessage })
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
                if(callback) {
                    callback()
                }
            })
    }
}

const reSendPinCodeSuccess = (dispatch, response, callback) => {
    dispatch({ type: SHOW_ERROR_MSG, payload: null })
    dispatch({ type: SHOW_ERROR_DIALOG, payload: false });
    dispatch({ type: RESEND_SUCCESS_MESSAGE, payload: true })

    if(callback) {
        callback()
    }
}

export const redirectToUserCredentialsPath = (userCredentialsPath, isPWA) => {
    return (dispatch, getState) => {
        
        const brandId = getState().brand.id;

        if (!isPWA) {
            if (userCredentialsPath === '/start-order/order-type') {
                dispatch(push(`/start-order/user-credentials`));
            } else if (userCredentialsPath === '/menu-screen/phone-number') {
                dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/menu-screen/user-credentials` });
            }
        } else {
            dispatch(push(`/brand/${brandId}/profile/login/user-credentials`));
        }
    }
}

export const redirectToForgottenEmailPath = (userCredentialsPath, isPWA) => {
    return (dispatch, getState) => {
        
        const brandId = getState().brand.id;

        if (!isPWA) {
            if (userCredentialsPath === '/start-order/order-type') {
                dispatch(push(`/start-order/forgotten-email`));
            } else if (userCredentialsPath === '/menu-screen/phone-number') {
                dispatch({ type: OPEN_START_ORDER_SCREEN, payload: `/menu-screen/forgotten-email` });
            }
        } else {
            dispatch(push(`/brand/${brandId}/profile/login/forgotten-email`));
        }
    }
}

export const resetErrMsg = () => {
    return (dispatch) => {

        dispatch({ type: SHOW_ERROR_MSG, payload: null })
        dispatch({ type: SHOW_ERROR_DIALOG, payload: false });
        dispatch({ type: RESEND_SUCCESS_MESSAGE, payload: false })
    };
};

export const resetLoginConfirmation = () => {
    return (dispatch) => {

        dispatch({ type: RESET_LOGIN_CONFIRMATION, payload: false })
    }
}

export const resetForgottenEmailConfirmation = () => {
    return (dispatch) => {

        dispatch({ type: RESET_FORGOTTEN_EMAIL_CONFIRMATION, payload: false })
    }
}

export const getCaptcha = () => {
    return (dispatch, getState) => {

        const url = `/captcha/`;

        axios(getState).get(url)
            .then(response => getCaptchaSuccess(dispatch, response))
            .catch((error) => {
                console.log(error);
                dispatch({ type: SHOW_ERROR_MSG, payload: error.response.data.userMessage })
                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            })
    }
}

const getCaptchaSuccess = (dispatch, response) => {
    dispatch({ type: GET_CAPTCHA_SUCCESS, payload: response.data })
}